import { ButtonHTMLAttributes } from "react"

export default function PrimaryButton({
    className = "",
    disabled,
    children,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            {...props}
            className={
                `
                    content-center items-center rounded-3xl border
                    border-transparent bg-bonza-lilac px-4 py-0 text-s
                    tracking-widest text-bonza-dark transition duration-150
                    ease-in-out

                    active:bg-bonza-dark

                    focus:bg-bonza-dark focus:outline-none focus:ring-2
                    focus:ring-indigo-500 focus:ring-offset-2

                    hover:bg-bonza-dark hover:text-white

                    ${disabled && "opacity-25"}
                ` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    )
}
